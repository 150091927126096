import { LanguageType } from './LanguageType';
import { ApiAssistanceIncidentType, ApiClaimIncidentType } from '../../api';

export const ru: LanguageType = {
  ok: 'OK',
  close: 'ЗАКРЫТЬ',
  next: 'ДАЛЕЕ',
  name: 'Имя',
  submit: 'ОТПРАВИТЬ',
  refresh: 'Обновить',
  phoneNumber: 'Телефон',
  selectCounty: 'Выберите округ',
  agreedArrivalTime: 'Согласованное время прибытия (необязательно)',
  all: 'Все',
  enterVehicleNumber: 'Введите регистрационный номер автомобиля',
  enterYourName: 'Введите ваше имя',
  enterYourPhoneNumber: 'Введите ваш номер телефона',
  refreshPageToSeeUpdates:
    'Мы обновили страницу. Пожалуйста, освежите страницу, чтобы увидеть последние изменения.',
  orderHelp: 'ЗАКАЗАТЬ ПОМОЩЬ',
  fileAClaimDescription:
    'Если наступил страховой случай, заявку на возмещение ущерба предлагаем заполнить здесь или в мобильном приложении BALTA!',
  fileClaim: 'ПОДАТЬ ИСК',
  iframe: {
    incidentType: 'Тип инцидента',
    addManually: 'Добавить вручную',
    selectDestinationWorkshop: 'Выберите мастерскую назначения',
    destinationName: 'Название назначения',
    destinationAddress: 'Адрес назначения',
    destinationNameError: 'Необходимо указать название назначения!',
    pickupLocation: 'Место забора',
    dateError: 'Дата и время должны быть в будущем!',
    region: 'Регион',
    service: 'Услуга',
    outOfRegion: 'Вне региона',
  },
  assistanceIncident: {
    WHAT_IS_THE_ISSUE: 'Что случилось?',
    [ApiAssistanceIncidentType.EmptyBattery]: 'Разряженный аккумулятор',
    [ApiAssistanceIncidentType.RunOutOfFuel]: 'Закончилось топливо',
    [ApiAssistanceIncidentType.FlatTires]: 'Спущенная шина',
    [ApiAssistanceIncidentType.StuckInSandSnowMud]: 'Застрял в песке/снегу/грязи',
    [ApiAssistanceIncidentType.DoorLocked]: 'Двери заблокированы',
    [ApiAssistanceIncidentType.UnknownTechnicalIssue]: 'Неизвестная техническая проблема',
    [ApiAssistanceIncidentType.TrafficAccident]: 'Дорожно-транспортное происшествие',
    [ApiAssistanceIncidentType.Other]: 'Другое',
  },
  claimIncident: {
    WHAT_IS_THE_ISSUE: 'Что случилось?',
    [ApiClaimIncidentType.WindshieldDamage]: 'Повреждение лобового стекла',
    [ApiClaimIncidentType.TrafficAccident]: 'Дорожно-транспортное происшествие',
    [ApiClaimIncidentType.HitWildAnimal]: 'Сбил животное',
    [ApiClaimIncidentType.TechnicalBreakdown]: 'Технический сбой',
    [ApiClaimIncidentType.Theft]: 'Угон',
    [ApiClaimIncidentType.VandalismOrFire]: 'Вандализм или пожар',
    [ApiClaimIncidentType.Other]: 'Другое',
    invalidClaim: 'Недействительня заявление!',
  },
  vehicleDetails: {
    mark: 'Марка',
    model: 'Модель',
    year: 'Год',
    error: 'Для получения помощи обратитесь к оператору.',
    callOperator: 'Звоните на',
    missingLicensePlate: 'Укажите регистрационный номер транспортного средства',
    invalidLicensePlate: 'Разрешены только цифры и буквы.',
    missingName: 'Укажите свое имя',
    missingPhoneNumber: 'Укажите свой номер телефона',
    requiredField: 'Обязательное поле',
    invalidPhoneNumber: 'Номер телефона должен состоять только из цифр',
  },
  findTowTruck: {
    message1: 'Уведомление операторов',
    message2: 'Поиск эвакуатора занимает не более 2 минут',
    message3: 'Установка геозоны',
    message4: 'Поиск ближайших эвакуаторов',
    message5: 'Найдены ближайшие эвакуаторы',
    message6: 'Передача информации о происшествии эвакуаторщикам',
    message7: 'Эвакуаторщики оценивают данные',
    message8: 'Ожидание ответа эвакуатора',
  },
  waitForTowTruck: {
    title: 'Помощь едет!',
    driverComing: 'едет на помощь',
    callDriver: 'Позвонить водителю',
    driverShouldBeThere: 'Водитель прибудет с минуты на минуту',
    eta: 'Расчетное время ожидания',
  },
  noTowTruckFound: {
    title: 'Извините! В данный момент нет свободных эвакуаторов.',
    details: `Наши операторы ищут для Вас эвакуатор. Пожалуйста, ожидайте звонка.`,
  },
  towTruckJobFinished: {
    title: 'Работа завершена',
  },
  map: {
    searchHint: 'Поиск',
    error: 'Не удалось связаться с сервером. Пожалуйста, проверьте подключение к сети.',
    pin: 'Перетащите карту, чтобы указать местоположение',
    pleaseSetPinToYourLocation: 'Пожалуйста, установите маркер в ваше местоположение.',
    unableToRetrieveLocation:
      'Местоположение не удалось определить. Пожалуйста, найдите местоположение на карте.',
    confirmLocation: {
      modalTitle: 'Подтвердите адрес',
      modalBody: 'Пожалуйста, подтвердите, что адрес <b>%{address}</b>.',
      confirm: 'Подтвердить',
      cancel: 'Отменить',
    },
    failedToRetrieveAddress: 'Не удалось получить адрес. Пожалуйста, попробуйте еще раз.',
    failedToSaveLocation: 'Не удалось сохранить местоположение. Пожалуйста, попробуйте еще раз.',
  },
  countdown: {
    minutes: 'Минуты',
    seconds: 'секунды',
  },
  errors: {
    noVehicleFound: 'Автомобиль не найден',
    tryAgain: 'Извините, что-то пошло не так. Повторите попытку позже',
    networkProblem:
      'Обнаружено отключение сети. Пожалуйста, убедитесь, что соединение работает, и повторите попытку.',
    failedToUpload:
      'Не удалось загрузить изображения. Пожалуйста, попробуйте еще раз или удалите фотографии, чтобы продолжить.',
    savingAccidentDetailsFailed:
      'Не удалось сохранить данные об аварии. Пожалуйста, попробуйте еще раз.',
    claimNotFound: 'Заявление не найдено.',
    savingPreferencesFailed:
      'Не удалось сохранить параметры общения. Пожалуйста, попробуйте еще раз.',
    claimSubmissionFailed: 'Не удалось отправить заявление. Пожалуйста, попробуйте еще раз.',
  },
  additionalDetails: {
    addPhotos: 'Нажмите и добавьте фото',
    requiredField: 'Обязательное поле!',
    descriptionLabel: 'Описание',
    pictures: 'Фотографии (необязательно)',
    email: 'Эл. почта (необязательно)',
    invalidEmail: 'Неверный формат эл. почта!',
    descriptionPlaceholder: {
      [ApiAssistanceIncidentType.EmptyBattery]:
        'Пожалуйста, опишите ситуацию. Как долго автомобиль находится в неподвижном состоянии? Находится ли автомобиль в гараже или на подземной парковке?',
      [ApiAssistanceIncidentType.RunOutOfFuel]: 'Пожалуйста, опишите ситуацию.',
      [ApiAssistanceIncidentType.FlatTires]:
        'Пожалуйста, опишите ситуацию. Какая шина спущена? Повреждена ли шина сбоку?',
      [ApiAssistanceIncidentType.DoorLocked]:
        'Пожалуйста, опишите ситуацию. Где ключи от машины? Где запасные ключи?',
      [ApiAssistanceIncidentType.StuckInSandSnowMud]: 'Пожалуйста, опишите ситуацию.',
      [ApiAssistanceIncidentType.UnknownTechnicalIssue]:
        'Пожалуйста, опишите проблему. Можно ли завести автомобиль? Можно ли перевести коробку передач в нейтральное положение? Можно ли отключить ручной тормоз? Поворачивается ли рулевое колесо?',
      [ApiAssistanceIncidentType.TrafficAccident]:
        'Пожалуйста, опишите аварию. Можно ли управлять автомобилем? Есть ли другие участники аварии? Была ли проинформирована полиция?',
      [ApiAssistanceIncidentType.Other]: 'Пожалуйста, опишите ситуацию.',
    },
  },
  landing: {
    welcome: 'Добро пожаловать',
    callYourInsuranceCompanyToGetLink:
      'Пожалуйста, позвоните в Вашу страховую компанию чтобы получить СМС с сылкой на форму.',
    openTheLinkAgain: 'Если Вы уже получили СМС, откройте ссылку снова.',
  },
  linkValidation: {
    linkValidationFailed: 'Не удалось проверить ссылку',
    linkHasExpired: 'Ссылка больше недействительна',
    linkAlreadyProcessed: 'Ссылка уже обработана',
    invalidLink: 'Неверная ссылка',
    sorrySomethingWentWrong: 'Извините, что-то пошло не так',
    callToGetNewLink: 'Позвоните чтобы получить новое СМС с сылкой',
  },
  trackDriver: {
    warning: 'УВЕДОМЛЕНИЕ',
    towtruckDriverChanged: 'Вашу заявку выполнит другой водитель!',
  },
  otherParties: {
    delete: 'Удалить',
    missingValue: 'Незаполненные поля',
    title: 'Другие участники',
    otherPartiesInvolved: 'Были ли вовлечены в происшествие другие участники?',
    otherPartiesInfo: 'Есть ли у вас их данные?',
    otherPartiesType: 'Выберите тип участника',
    vehiclePartyType: 'Это транспортное средство?',
    objectPartyType: 'Это объект?',
    vehicleParty: {
      number: 'Номер транспорта участника',
      name: 'Имя контакта (необязательно)',
      personalCode: 'Персональный код (необязательно)',
      phoneNumber: 'Тел.номер (необязательно)',
      email: 'Эл. почта (необязательно)',
    },
    objectParty: {
      description: 'Опишите объект-участник происшествия',
      name: 'Имя контакта (необязательно)',
      personalCode: 'Персональный код (необязательно)',
      phoneNumber: 'Тел.номер (необязательно)',
      email: 'Эл. почта (необязательно)',
    },
    addAnotherParty: 'Добавить еще одного участника',
    yes: 'ДА',
    no: 'НЕТ',
    invalidPartyPresentErrorMessage:
      'В некоторых участниках отсутствует необходимая информация. Пожалуйста, заполните информацию или удалите участника!',
  },
  notifierInfo: {
    title: 'ИНФОРМАЦИЯ УВЕДОМИТЕЛЯ',
    driver: 'Водитель',
    name: 'Полное имя',
    personalCode: 'Персональный код (необязательно)',
    telephone: 'Номер телефона',
    email: 'Эл. почта',
    confirmUserIsDriver: 'Были ли вы за рулем во время инцидента?',
    notifyUserTofillDriverDetails: '(Если нет, то предоставьте информацию о водителе)',
    affirm: 'ДА',
    deny: 'НЕТ',
    errors: {
      name: 'Полное имя обязательно!',
      telephone: 'Номер телефона должен состоять только из цифр и не может быть пустым!',
      email: 'Эл. почта должен быть правильным эл. почта адресом!',
      personalCode: 'Персональный код должен быть в правильном формате!',
    },
  },
  insuranceSelection: {
    title: 'Страхование',
    subtitle: 'Мы отправим заявление страховщику',
    cascoSelect: 'Выберите страхование Каско',
    mtplSelect: 'Выберите ОСАГО',
    CascoNoneApplicable: 'Нет страховки Каско',
    MtplNotSure: 'Не знаю',
    mandatorySelectionError: 'Выбор обязателен!',
    casco: 'Каско',
    mtpl: 'ОСАГО',
  },
  accidentDetails: {
    title: 'Детали проишествия',
    affirmation: 'ДА',
    deny: 'НЕТ',
    dateTime: 'Введите дату и время аварии',
    location: 'Место проишествия',
    description: 'Описание проишествия',
    culprit: {
      responsibleParty: 'Кто был виновен в проишествии?',
      notifier: '%{licensePlate} стал причиной аварии',
      otherParty: 'Другая сторона виновна',
      unknown: 'Неизвестно',
    },
    dateError: 'Дата и время обязательны!',
    mandatoryLocation: 'Местонахождение обязательно',
    authoritiesNotified: 'Уведомлены ли соотвествующие органы?',
    personalInjury: 'Есть ли у вас какая-либо травма?',
    accidentDateIsMandatory: 'Дата инцидента обязательна',
    accidentTimeIsMandatory: 'Время инцидента обязательно',
    accidentDescriptionIsMandatory: 'Описание проишествия обязательно',
  },
  claimReceived: {
    title: 'ЗАЯВЛЕНИЕ ПОЛУЧЕНО',
    body: 'Ваш случай зарегестрирован. При возникновении вопросов мы с Вами свяжемся. Сейчас можете закрыть страницу.',
  },
  communicationPreference: {
    title: 'Предпочитаемый способ связи',
    email: 'Эл. почта',
    sms: 'SMS',
    error: 'Хотя бы один из вариантов должен быть выбран!',
    submit: 'ОТПРАВИТЬ',
  },
  damageLocation: {
    title: 'Место повреждения',
  },
  photos: {
    title: 'ЗАГРУЗКА ФОТО',
    skipForNow: 'ПРОПУСТИТЬ',
    skipStep: 'ПРОПУСТИТЬ',
    takePhotos: 'СНЯТЬ ФОТО',
    uploadFromGallery: 'ЗАГРУЗИТЬ ИЗ ГАЛЕРЕИ',
    photoUploadLimitReached:
      'Всего в эту форму можно добавить %{number} изображений. Остальные были отклонены',
    skipTitle:
      'Вы собираетесь пропустить загрузку фотографий. Фотографии важны для подачи заявления. Не забудьте добавить их позже.',
    turnYourPhoneHorizontally: 'Поверните телефон горизонтально',
    takeAPhotoOfDamage: 'Сделайте снимок повреждения',
    uploadSuggestions: {
      generalViewOfTheVehicle: 'Фотография общего вида автомобиля с регистрационным номером',
      damagePicture: 'Фотографии повреждений (дальние и ближние)',
      availableDocuments: 'Извещение о происшествии или другой письменный документ (если имеется)',
      driversLicense: 'Фотография водительских прав',
      scenePicture: 'Фотографии места происшествия (если имеются)',
    },
  },
  partnerLanding: {
    title: 'Как мы можем помочь?',
    fileAClaim: 'Подать заявление о страховом случае',
    roadsideAssistance: 'Помощь на дороге',
  },
  caseStatus: {
    title: 'Статус страхового случая',
    registration: '№ регистрации.',
    steps: {
      caseRegistered: 'Страховой случай зарегистрирован',
      caseRegisteredDone: 'Страховой случай зарегистрирован',
      vehicleDeliveredToWorkshop: 'Автомобиль доставлен в мастерскую',
      vehicleDeliveredToWorkshopDone: 'Автомобиль доставлен в мастерскую',
      estimatedDiagnostics: 'Ожидаемая диагностика',
      estimatedDiagnosticsDone: 'Диагностика завершена',
      preparingRepairEstimate:
        'Подготовка к составлению калькуляции для устранения повреждений / по ремонту',
      preparingRepairEstimateDone: 'Составление калькуляции завершено',
      confirmingRepairEstimate: 'Подтверждение калькуляции по ремонту',
      confirmingRepairEstimateDone: 'Калькуляция подтверждена',
      estimatedArrivalOfSpareParts: 'Ожидаемое время получения запчастей',
      estimatedArrivalOfSparePartsDone: 'Запчасти получены',
      repairingStarts: 'Начало ремонта',
      repairingStartsDone: 'Ремонт начат',
      estimatedEndOfRepair: 'Предпологаемое время окончания ремонта',
      estimatedEndOfRepairDone: 'Ремонт завершен',
      pickUpVehicle: 'Транспортное средство готово к выдаче',
      pickUpVehicleDone: 'Транспортное средство выдано',
    },
    nextStep: 'Вашим делом занимаются. Теперь следующий шаг',
    workshopDetails: 'Подробности мастерской',
    errors: {
      invalidLink: 'Ссылка недействительна',
      caseNotFound: 'Страховой случай не найден',
    },
  },
  terms: {
    agreement: 'Я согласен(на) с условиями использования и политикой конфиденциальности',
    termsAndPrivacy: 'Условия и политика конфиденциальности',
    error: 'Вы должны согласиться с условиями и политикой конфиденциальности',
    termsTitle: 'ОБЩИЕ УСЛОВИЯ ОБСЛУЖИВАНИЯ',
    privacyTitle: 'ПОЛИТИКА КОНФИДЕН-ЦИАЛЬНОСТИ',
  },
  workshop: {
    choose: 'ВЫБЕРИТЕ МАСТЕРСКУЮ',
    hint: '<h6><b>Выберите мастерскую, щелкнув <img src = %{img} width="20px" height="20px" /> на карте</b></h6>',
  },
  feedback: {
    title: 'Ваш отзыв имеет значение!',
    subText: 'Сообщите нам, каким было ваше общее впечатление.',
    emptyRatingText: 'Пожалуйста, оцените свой опыт.',
    commentLabel: 'Другие комментарии (необязательно)',
    expired: 'Срок действия ссылки истек!',
    notFound: 'Ссылка недействительна!',
    alreadyGiven: 'Вы уже оставили отзыв!',
    success: 'Спасибо! Ваш отзыв успешно обработан!',
    submissionError: 'Не удалось отправить отзыв! Пожалуйста, повторите попытку позже.',
  },
  assistanceLanding: {
    title: 'Заказать помощь на дороге',
    description:
      '<p>Заказ помощи на дороге нажатием кнопки START на <b>75% быстрее</b>, чем разговор с оператором.<br><br> Это простой 4-шаговый процесс, который занимает до 2 минут. Ваше местоположение определяется с помощью GPS.</p>',
    start: 'СТАРТ',
  },
};
