import React, { useEffect, useState } from 'react';
import { Button, Container, Navbar } from 'react-bootstrap';
import { getEditableIframeDetailsByUuid } from '../iframeActions';
import { setLocaleWithFallback } from '../../../locale/languageActions';
import { connect, ResolveThunks } from 'react-redux';
import { getLanguageParamFromUrl } from '../../../common/utils/URLUtils';
import { isSupportedLocale } from '../../../locale/locale';
import { ClientError } from '../../../common/exception/ClientError';
import { useParams } from 'react-router-dom';
import { EditableIframeResponse } from '../../../api';
import { Loader } from '../../../components/loader/Loader';
import { Translate } from 'react-redux-i18n';
import LanguageSelector from '../../../components/languageSelector/LanguageSelector';
import { useServiceWorker } from '../../../service-worker/useServiceWorker';

type PathParameters = {
  uuid: string;
};

export const EditViewIframe: React.FC<React.PropsWithChildren<EditViewIframeProps>> = ({
  getEditableIframeInfo,
  setLocale,
}) => {
  const { reloadPage } = useServiceWorker();

  const { uuid } = useParams<PathParameters>();
  const [initiated, setInitiated] = useState(false);
  const [error, setError] = useState(false);
  const [iframeInfo, setIframeInfo] = useState<EditableIframeResponse>();
  const urlLanguage = getLanguageParamFromUrl();
  useEffect(() => {
    initialize();
    urlLanguage && isSupportedLocale(urlLanguage) && setLocale(urlLanguage);
  }, []);

  const initialize = async () => {
    setInitiated(false);
    if (uuid) {
      try {
        const iframe = await getEditableIframeInfo(uuid);
        setIframeInfo(iframe);
      } catch (e) {
        if (e instanceof ClientError) {
          setError(true);
        }
      } finally {
        setInitiated(true);
      }
    }
  };
  return (
    <>
      {!initiated && <Loader />}
      {initiated && !error && (
        <div>
          <Navbar
            expand='lg'
            className='theme-border justify-content-between'
            style={{ borderLeft: `4px solid #36BB7B` }}
          >
            <img
              src='https://diggz46oa5qa0.cloudfront.net/ServiceTheme/MVP3/YAWAY.svg'
              style={{ maxHeight: 55, maxWidth: 150 }}
              alt='website logo'
            />
            <LanguageSelector />
          </Navbar>
          <Container className='content yaway-container mt-5' fluid>
            <h2 className='text-center'>Yaway Id</h2>
            <p className='text-center mt-1 font-weight-bold'>{iframeInfo?.yawayId ?? ''}</p>
            <div className='mt-4 space-y-2'>
              <p>
                <Translate value='iframe.region' />:{' '}
                <span className='font-weight-bold'>
                  {iframeInfo?.region ? (
                    iframeInfo.region
                  ) : (
                    <Translate value='iframe.outOfRegion' />
                  )}
                </span>
              </p>
              <p>
                <Translate value='caseStatus.title' />:{' '}
                <span className='font-weight-bold'>{iframeInfo?.status}</span>
              </p>
              <p>
                {iframeInfo?.slaTime != null && (
                  <>
                    <Translate value='agreedArrivalTime' />
                    {': '}
                    <span className='font-weight-bold'>
                      {iframeInfo?.slaTime
                        ? new Date(iframeInfo.slaTime).toLocaleString('en-US', {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false,
                          })
                        : ''}
                    </span>
                  </>
                )}
              </p>
              <p>
                <Translate value='iframe.service' />:{' '}
                <span className='font-weight-bold'>{iframeInfo?.service ?? ''}</span>
              </p>
            </div>
            <div className='text-center'>
              <Button
                style={{ background: '#36BB7B' }}
                size='lg'
                className='next-btn'
                onClick={reloadPage}
              >
                {<Translate value='refresh' />}
              </Button>
            </div>
          </Container>
        </div>
      )}
      {error && (
        <Container className='content yaway-container mt-5' fluid>
          <div className='text-center'>
            <p className='text-center link-status-text'>
              <Translate value='linkValidation.invalidLink' />
            </p>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProp = {
  getEditableIframeInfo: getEditableIframeDetailsByUuid,
  setLocale: setLocaleWithFallback,
};

export type EditViewIframeProps = ResolveThunks<typeof mapDispatchToProp>;

export default connect(null, mapDispatchToProp)(EditViewIframe);
